/* eslint-disable */
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetAvdLoginUrlResponseDto } from '../dto/get-avd-login-url-response-dto';

export function httpGetBffAvdGetAvdLoginUrl(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'body' }): Observable<GetAvdLoginUrlResponseDto>;
export function httpGetBffAvdGetAvdLoginUrl(http: HttpClient, options?: { reportProgress?: boolean; observe?: 'events' }): Observable<HttpEvent<GetAvdLoginUrlResponseDto>>;
export function httpGetBffAvdGetAvdLoginUrl(http: HttpClient, options: { reportProgress?: boolean; observe?: 'body' | 'events' } = {}): Observable<any> {
  return http.get<GetAvdLoginUrlResponseDto>(`{placeholder}/bff/avd/getAvdLoginUrl`, options as any);
};

export const urlGetBffAvdGetAvdLoginUrl = (baseUrl: string) => {
  return `${baseUrl}/bff/avd/getAvdLoginUrl`;
};
